<nz-result
  class="ng-page-error"
  nzStatus="404"
  nzTitle="404"
  nzSubTitle="Sorry, you are not authorized to access this page."
>
  <div nz-result-extra>
    <button nz-button nzType="primary" [routerLink]="['']" >Back Home</button>
  </div>
</nz-result>
