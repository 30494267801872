import { createReducer, on } from '@ngrx/store';
import { actionConfigLedger, actionDropdownLedger } from './ledger.action';
import { IFileObject } from '../../core/interfaces';

export interface IListConfigLedger {
  lcId: number;
  lcType: string;
  lcImageDesktop: IFileObject;
  lcImageMobile: IFileObject;
  lcDetail: string;
}

export interface IListDropdownLedger {
  ldId: number;
  ldType:  string;
  ldTitle:  string;
}

export interface IConfigDataLedger {
  config: IListConfigLedger[];
  dropdown: { [key: string]: IListDropdownLedger[] };
}

export const initialStateData: IConfigDataLedger = {
  config: [],
  dropdown: {},
};

export const ledgerReducer = createReducer<IConfigDataLedger>(
  initialStateData,
  on(actionConfigLedger, (state, payload) => {
    return {
      ...state,
      config: payload.config,
    };
  }),
  on(actionDropdownLedger, (state, payload) => {
    return {
      ...state,
      dropdown: payload.dropdown,
    };
  })
);
